<template>
  <div>
    <GameHistoryWidgets :widgets-data="summaryData" />
    <b-card>
      <b-row class="mb-3">
        <b-col>
          <h6>
            {{ `${$t('fields.result')} (${filteredList.length})` }}
          </h6>
        </b-col>
        <b-col>
          <b-row>
            <b-col>
              <b-input-group>
                <b-form-input
                  v-model="filterPlayerUsername"
                  type="search"
                  :placeholder="`${$t('fields.search')}... (${$t(
                    'user.username'
                  )})`"
                />
              </b-input-group>
            </b-col>
            <b-col>
              <b-input-group class="ml-md-2">
                <b-form-input
                  v-model="filterRoundId"
                  type="search"
                  :placeholder="`${$t('fields.search')}... (${$t(
                    'games.room_id'
                  )})`"
                />
              </b-input-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <!-- Table -->
      <b-table
        :items="gameTransactions.data"
        :fields="fields"
        :busy="isFetchingTransactions"
        responsive
        show-empty
      >
        <template #cell(id)="data">
          {{ `${data.value}` }}
        </template>
        <template #cell(username)="data">
          {{ data.value }}
        </template>
        <template #cell(txnId)="data">
          {{ data.value || 'Coming..' }}
        </template>
        <template #cell(roundId)="data">
          {{ data.value || 'Coming..' }}
        </template>
        <template #cell(winAmount)="data">
          <b-badge
            v-if="Number(data.value) > 0.0"
            variant="primary"
          >
            {{ data.value | currency }}
          </b-badge>
          <span v-else>
            {{ data.value | currency }}
          </span>
        </template>
        <template #cell(gameName)="data">
          {{ data.value || '-' }}
        </template>
        <template #cell(gameType)="data">
          <b-badge variant="light">
            {{ data.value || 'Slot' }}
          </b-badge>
        </template>
        <template #cell(has_details)="data">
          <b-button
            v-if="data.value === 'url'"
            size="sm"
            @click="fetchGameBetDetail(data.item.id)"
          >
            ดู
          </b-button>
        </template>
        <template #cell(createdAt)="data">
          {{ data.value | datetime }}
        </template>
        <template #cell(status)="data">
          <b-badge
            v-if="data.value === 1"
            variant="success"
          >
            {{ $t('utils.status.finished') }}
          </b-badge>
          <b-badge
            v-else-if="data.value === 0"
            variant="warning"
          >
            {{ $t('utils.status.bet') }}
          </b-badge>
          <b-badge
            v-else-if="data.value === 2"
            variant="danger"
          >
            {{ $t('utils.status.canceled') }}
          </b-badge>
          <b-badge
            v-else-if="data.value === 3"
            variant="danger"
          >
            VOID
          </b-badge>
          <b-badge
            v-else-if="data.value === 4"
            variant="danger"
          >
            INVALID
          </b-badge>
          <b-badge
            v-else-if="data.value === 5"
            variant="danger"
          >
            BONUS
          </b-badge>
          <b-badge
            v-else-if="data.value === 6"
            variant="danger"
          >
            JACKPOT
          </b-badge>
        </template>
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>
        <template #empty="">
          <p class="text-center text-muted">
            {{
              $t('messages.nothing_here')
            }}
          </p>
        </template>
      </b-table>
      <!-- pagination -->
      <pagination-input
        :per-page="limit"
        :total="total"
        @update="(val) => page = val"
      />
    </b-card>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'ProviderGameHistory',
  components: {
    GameHistoryWidgets: () => import('../HistoryWidgets.vue'),
  },
  props: {
    gameSlug: {
      type: [String, Number],
      required: true,
      default: 1,
    },
    selectedDate: {
      type: String,
      default: '',
    },
    selectedMasterId: {
      type: String,
      default: '',
    },
    selectedAgentId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      summaryData: [],
      totalRows: 0,
      selectedLimit: 50,
      page: 1,
      filterAgent: null,
      filterRoundId: '',
      filterPlayerUsername: '',
      isLoading: true,
      filteredList: [],
      fields: [
        {
          key: 'id',
          label: '#',
          thStyle: {
            minWidth: '100px',
          },
        },
        {
          key: 'createdAt',
          label: `${this.$t('fields.createdAt')}`,
          thStyle: {
            minWidth: '130px',
          },
        },
        {
          key: 'agent_name',
          label: `${this.$t('agent.name')}`,
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'username',
          label: `${this.$t('user.username')}`,
          thStyle: {
            minWidth: '170px',
          },
        },
        {
          key: 'txnId',
          label: 'เลขธุรกรรม',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'roundId',
          label: 'รอบที่',
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'betAmount',
          label: `${this.$t('games.bet_amount')}`,
          class: 'text-right',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'winAmount',
          label: `${this.$t('games.win_amount')}`,
          class: 'text-right',
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'gameName',
          label: 'ชื่อเกม',
          class: 'text-center',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'gameType',
          label: 'ชนิด',
          class: 'text-center',
          thStyle: {
            minWidth: '100px',
          },
        },
        {
          key: 'has_details',
          label: '-',
          class: 'text-center',
          thStyle: {
            minWidth: '50px',
          },
        },
        {
          key: 'status',
          label: `${this.$t('fields.status')}`,
          class: 'text-center',
          thStyle: {
            minWidth: '100px',
          },
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['isMaster', 'userInfo', 'isOwner']),
    ...mapState({
      gameTransactions: (state) => state.game.gameTransactions,
      isFetchingTransactions: (state) => state.game.isFetchingTransactions,
    }),
    agentId() {
      return this.userInfo.agentId
    },
    limit() {
      return this.gameTransactions.totalItemOnPage
    },
    total() {
      return this.gameTransactions.totalItems
    },
  },
  watch: {
    selectedDate(val) {
      if (val) {
        this.fetchData()
      }
    },
    gameSlug(val) {
      if (val) {
        this.fetchData()
      }
    },
    page(val) {
      if (val) {
        this.$addPageToLocation(val)
        this.fetchData()
      }
    },
    filterAgent() {
      this.fetchData()
    },
    filterRoundId() {
      this.fetchData()
    },
    filterPlayerUsername() {
      this.fetchData()
    },
    selectedMasterId() {
      this.fetchData()
    },
    selectedAgentId() {
      this.fetchData()
    },
  },
  created() {
    if (this.agentId) {
      this.fetchData()
    }
  },
  methods: {
    ...mapActions(['fetchTransaction', 'fetchBetDetail']),
    fetchData() {
      this.fetchGameTransaction()
    },
    fetchGameTransaction() {
      const date = dayjs(this.selectedDate || new Date()).format('YYYY-MM-DD')
      this.summaryData = [
        {
          icon: 'ActivityIcon',
          name: `${this.$t('pages.mini_games.total_games')}`,
          value: '0 ครั้ง',
          color: 'light-info',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'DollarSignIcon',
          name: `${this.$t('pages.mini_games.total_bet')}`,
          value: '0 บาท',
          color: 'light-warning',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'CoffeeIcon',
          name: `${this.$t('games.win')}`,
          value: '0 บาท',
          customClass: 'mb-2 mb-xl-0',
          color: 'light-success',
        },
      ]
      this.fetchTransaction({
        id: this.gameSlug,
        data: {
          page: this.page,
          limit: this.selectedLimit, 
          search: this.filterRoundId || this.filterPlayerUsername,
          roundId: this.roundId || '',
          agentId: this.selectedAgentId,
          masterId: this.selectedMasterId,
          date,
        },
      })
    },
    async fetchGameBetDetail(betId) {
      const data = await this.fetchBetDetail({
        gameId: this.gameSlug,
        betId
      })
      if (data.url) {
        window.open(data.url, '_blank')
      }
    },
  },
}
</script>
